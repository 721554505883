<template>
  <!-- 购物车 -->
  <div id="app">
    <div class="cart-con">
      <div class="cart-ban">
        <img src="@/assets/img/leetcode.jpg" />
      </div>
      <div class="cson-container">
        <div class="center-log">我的购物车</div>
        <template>
          <el-table
            ref="multipleTable"
            :data="tableData"
            class="cart-table"
            style="width: 100%; border-radius: 8px; font-size: 16px"
            tooltip-effect="dark"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              label="全选"
              type="selection"
              width="90"
            ></el-table-column>
            <el-table-column label="课程" width="680">
              <template slot-scope="scope">
                <div class="lesson">
                  <img :src="scope.row.imgUrl" class="img" />
                  <h2>{{ scope.row.objectName }}</h2>
                  <!--                                    <p>-->
                  <!--                                        <span v-for="(item,index) in scope.row.tags">{{item}}</span>-->
                  <!--                                    </p>-->
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="金额"
              prop="money"
              width="245"
            >
              <template slot-scope="scope">
                <div class="money">¥{{ scope.row.price }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" show-overflow-tooltip>
              <template slot-scope="scope">
                <i
                  class="el-icon-close delete"
                  @click="deleteRow(scope.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="sum">
          <span
            >（共 {{ tableData.length }} 门，已选择
            {{ multipleSelection.length }} 门）</span
          >
          <p>
            总计金额：<label>¥{{ totalPrice }}</label>
          </p>
          <button @click="confirm">
            <img class="icon" src="@/assets/img/icon-cart.png" />去结算
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { deleteShoppingCart, getShoppingCart } from "../../service";
import { setStorage, setStorageForArray } from "../../utils/common";

export default {
  data() {
    return {
      tableData: [
        {
          id: 1,
          title: "Flutter从入门到进阶 实战携程网App",
          money: 700,
          tags: ["标签1", "标签名字", "标签"],
        },
        {
          id: 2,
          title: "Flutter从入门到进阶 实战携程网App",
          money: 300,
          tags: ["标签1", "标签"],
        },
        {
          id: 3,
          title: "Flutter从入门到进阶 实战携程网App",
          money: 200,
          tags: ["标签1", "标签名字"],
        },
        {
          id: 4,
          title: "Flutter从入门到进阶 实战携程网App",
          money: 4900,
          tags: ["标签1", "标签名字", "标签"],
        },
        {
          id: 5,
          title: "Flutter从入门到进阶 实战携程网App",
          money: 100,
          tags: ["标签1", "标签"],
        },
        {
          id: 6,
          title: "Flutter从入门到进阶 实战携程网App",
          money: 660,
          tags: ["标签1", "标签名字", "标签"],
        },
        {
          id: 7,
          title: "Flutter从入门到进阶 实战携程网App",
          money: 1000,
          tags: ["标签1", "标签名字", "标签"],
        },
      ],
      multipleSelection: [],
      totalPrice: 0,
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.getShopcart();
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
      if (this.multipleSelection) {
        this.totalPrice = 0;
        let price = 0;
        this.multipleSelection.forEach((m) => (price += m.price));
        this.totalPrice = price;
      }
    },
    confirm() {
      if (this.multipleSelection.length === 0) {
        this.$message.error("必须至少勾选一件商品");
        return;
      }

      setStorageForArray("orderSelect", this.multipleSelection);
      setStorage("totalprice", this.totalPrice);
      this.$router.push("/confirm");
    },
    deleteRow(row) {
      let id = row.id;
      deleteShoppingCart({ shopCartId: id })
        .then((res) => {
          console.log("delete shoppingcart result:", res);
          this.getShopcart();
        })
        .catch((err) => {
          console.log("delete shoppingcart fail:", err);
        });
    },
    /**
     * 获取购物车列表
     */
    getShopcart() {
      getShoppingCart()
        .then((res) => {
          console.log("购物车：", res);
          if (res.success) {
            this.tableData = this.setShopCartDatas(res.result.records);
          }
        })
        .catch((err) => {
          console.log("购物车出错：", err);
        });
    },
    setShopCartDatas(datas) {
      if (datas && datas.length > 0) {
        return datas;
      }
      return [];
    },
  },
};
</script>

<style>
</style>
